@import url(https://fonts.googleapis.com/css?family=Montserrat:100,300,400,700);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
	--info-dark: rgba(35,82,124);  
	--info-med: rgba(140,198,255);
	--info-light: rgba(232,243,255);
	
	--success-dark: rgba(0,111,1);  
	--success-med: rgba(127,183,128);
	--success-light: rgba(229,240,229);
	
	--custom-dark: rgba(35,82,124);  
	--custom-med: rgba(145,168,189);
	--custom-light: rgba(233,237,241);
	
	--dark-custom-dark: rgba(10,13,16);
	--dark-custom-med: rgba(108,109,111);
	--dark-custom-light: rgba(181,182,183);
	
	--error-dark: rgba(209,53,41);
	--error-med: rgba(232,154,148);
	--error-light: rgba(250,234,233);
	--positive-medium: rgba(42, 148, 70);
}

html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	min-height: 100vh;
	display: grid;
	place-items: center;
	padding: 1rem;
	font-family: Montserrat,Open Sans,sans-serif;
	color: var(--custom-dark);
	background: #fff;
	line-height: 1;
	font-weight: 400;
	letter-spacing: 0.1rem;
}

body.dark {
	min-height: 100vh;
	display: grid;
	place-items: center;
	padding: 1rem;
	font-family: Montserrat,Open Sans,sans-serif;
	background: #0a0d10;
	color: rgb(234, 236, 239);
	line-height: 1;
	font-weight: 400;
	letter-spacing: 0.1rem;
}

body a{
	color: var(--custom-dark);
}

body.dark a{
	color: rgb(234, 236, 239);
}

#toast{	
	position: fixed;
    width: 230px;
    height: 50px;
    bottom: 80px;
    left: 5;
    background-color: #fff;
    padding: 1px;
    border-radius: 8px;
    text-align: center;
    z-index: 10;
    box-shadow: 0 0 20px rgb(0 0 0 / 30%);
    visibility: hidden;
    opacity: 0;
    -ms-display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
#toast.show{
	visibility:visible;
	animation:fadeInOut 15s;
}
.dark #toast{
	background: #0a0d10;
}

@keyframes fadeInOut{
	5%,95%{opacity:1;bottom:50px}
	15%,85%{opacity:1;bottom:80px}
}

.maincontainer, .maincontainer2{
	background: #fff;
	position: relative;
	width: 100%;
	z-index: 1;
	max-width: 40rem;
	left: 50%;
	margin-left: -20rem;
	position: relative;
	padding: 1rem;
	border-radius: 1.5rem;
	box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.006), 0 6.7px 5.3px rgba(0, 0, 0, 0.008), 0 12.5px 10px rgba(0, 0, 0, 0.01), 0 22.3px 17.9px rgba(0, 0, 0, 0.012), 0 41.8px 33.4px rgba(0, 0, 0, 0.014), 0 100px 80px rgba(0, 0, 0, 0.02);
}

.wave {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: 75vh;
	--fill: var(--custom-light);
}
.wave svg {
	height: 320px;
	width: 100%;
	transform: translateY(16px);
}
.wave svg path {
	fill: var(--fill);
}
.wave div {
	height: 100%;
	width: 100%;
	background: var(--fill);
}


.dark .maincontainer, .dark .maincontainer2{
	background: var(--dark-custom-dark);
	position: relative;
	width: 100%;
	z-index: 1;
	max-width: 40rem;
	left: 50%;
	margin-left: -20rem;
	padding: 1rem;
	border-radius: 1.5rem;
	box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.006), 0 6.7px 5.3px rgba(0, 0, 0, 0.008), 0 12.5px 10px rgba(0, 0, 0, 0.01), 0 22.3px 17.9px rgba(0, 0, 0, 0.012), 0 41.8px 33.4px rgba(0, 0, 0, 0.014), 0 100px 80px rgba(0, 0, 0, 0.02);
}


.dark .wave {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: 75vh;
	--fill: #000;
}
.dark .wave svg {
	height: 320px;
	width: 100%;
	transform: translateY(16px);
}
.dark .wave svg path {
	fill: var(--fill);
}
.dark .wave div {
	height: 100%;
	width: 100%;
	background: var(--fill);
}


.icon-camera:before{
	content:"\1F4F7";
}

.icon-eye:before{
	content:"\1F441";
}
.icon-eye-blocked:before{
	content:"\1F5B8";
}

.password{
	position:relative
}

.password .action_password{
	position:absolute;
	right:20px;
	top:18px;
	z-index:2;
	cursor:pointer
}

.password .clicked:after{
	-webkit-transform:translateZ(0) translate(-50%,-50%) scale(1)!important;
	transform:translateZ(0) translate(-50%,-50%) scale(1)!important
}

.password .action_password:after {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateZ(0) translate(-50%,-50%) scale(0);
    transform: translateZ(0) translate(-50%,-50%) scale(0);
    -webkit-transition: transform .5s ease 0s;
    -webkit-transition-delay: .5s 0s;
    transition: transform .5s ease 0s;
    border-radius: 50%;
    background-color: hsla(0,0%,86.7%,.25);
}

.fixpost:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 12px;
    -webkit-transition: transform .2s ease-out 0s;
    -webkit-transition-delay: .2s 0s;
    transition: transform .2s ease-out 0s;
    -webkit-transform: translateZ(0) translate(-110%);
    transform: translateZ(0) translate(-110%);
}

.scan{
	position:relative
}

.scan .camera{
	position:absolute;
	right:20px;
	top:18px;
	z-index:2;
	cursor:pointer
}

.scan .clicked:after{
	-webkit-transform:translateZ(0) translate(-50%,-50%) scale(1)!important;
	transform:translateZ(0) translate(-50%,-50%) scale(1)!important
}

.scan .camera:after {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateZ(0) translate(-50%,-50%) scale(0);
    transform: translateZ(0) translate(-50%,-50%) scale(0);
    -webkit-transition: transform .5s ease 0s;
    -webkit-transition-delay: .5s 0s;
    transition: transform .5s ease 0s;
    border-radius: 50%;
    background-color: hsla(0,0%,86.7%,.25);
}

.stack {
  --space: 0.7rem;
}

.stack > * + * {
  margin-top: var(--space);
}

.container__canvas {
  margin-bottom: 1rem;
}
.container__control {
  display: grid;
  grid-template-columns: 112px 1fr;
  align-items: center;
  height: 3rem;
}

.wallet__content {
    min-width: 350px;
	grid-column: 2;
	padding: 1rem 0.1rem;
}

.po-wrap {
    padding: 5px 20px 30px;
}

.po-crypto__email-step {
    /* border: 1px solid var(--custom-med); */
    border-radius: 4px;
    padding: 20px 25px 30px;
    position: relative;
}
  
.container__control-label {
  font-weight: 500;
}


.container__control--switch .switch-btn {
	--height: 3rem;
	position: relative;
	height: var(--height);
	margin: 0 auto;
	border-radius: calc(var(--height) / 2);
	border: 0;	
	background: var(--custom-light);
	grid-template-columns: 1fr 1fr 1fr;
	align-items: center;
	width: 100%;
}

.dark .container__control--switch .switch-btn {
	background: var(--dark-custom-med);
	width: 100%;
}

.container__control--switch .switch-btnn {
	--height: 3rem;
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: center;
	width: 100%;
	height: var(--height);
	margin: 0 auto;
	border-radius: calc(var(--height) / 2);
	border: 0;
	background: var(--custom-light);
	pointer-events: none;
}
.container__control--switch .switch-btnn {
	background: var(--dark-custom-dark);
}

.container__control--input .text-input {  
	border: 1px solid var(--custom-dark);
	border-radius: 5px;
	position: relative;
	z-index: 0;	
	padding: 0 20px;
	color: var(--custom-dark);
	height: 3rem;
	line-height: 3rem;
	margin: auto;
	font-size: 1.23rem;
	text-align: left;
	width: 100%;
	font-weight: 300;
	font-family: Montserrat,Open Sans,sans-serif;
}

.dark .container__control--input .text-input, .dark .container__control--select .select-input {  
	border: 1px solid var(--dark-custom-light);
	color: var(--dark-custom-light);
	background: var(--dark-custom-dark);
}

.container__control--select .select-input {
  
	border: 1px solid var(--custom-dark);
	border-radius: 5px;
	position: relative;
	padding: 0 20px;
	color: var(--custom-dark);
	height: 3rem;
	line-height: 3rem;
	margin: auto;
	font-size: 1.23rem;
	text-align: left;
	width: 100%;
	appearance: none;
	background: var(--custom-light);
	font-weight: 300;
	font-family: Montserrat,Open Sans,sans-serif;
}

.container__control--switch .switch-btn > * {
  pointer-events: cursor;
}
.container__control--switch .switch-btn__label {
	color: var(--custom-dark);
	width: 33.3%;
	display: inline-table;
	z-index: 3;
    position: inherit;
	padding: 15px 0 15px 0;
}
.container__control--switch .switch-btn__marker {
  position: absolute;
  width: calc(33.3% - 0.2rem);
  height: calc(100% - 0.5rem);
  background: #fff;  
  border-radius: calc(var(--height) / 2);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
  transition: transform 200ms ease-in-out;
  margin-left: 0.3rem;
  padding: 0 30px;
  top: 4px;
}

.dark .container__control--switch .switch-btn__marker, .dark .container__control--switch .switch-btnn__marker {
	background: var(--dark-custom-dark);
	color: #fff;
}


.dark .container__control--switch .switch-btn__label{
	color: #fff;
	width: 33.3%;
	display: inline-table;
	z-index: 3;
    position: inherit;
	padding: 15px 0 15px 0;
}

.container__control--switch .switch-btnn__marker {
  position: absolute;
  width: calc(100% - 0.42rem);
  height: calc(100% - 0.5rem);
  background: #fff;
  border-radius: calc(var(--height) / 2);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
  transition: transform 200ms ease-in-out;
  margin-left: 0.2rem;
  padding: 0 30px;
  top: 4px;
}

.container__control--color input {
  
    border: 1px solid var(--custom-dark);
	border-radius: 5px;
	position: relative;
	z-index: 0;
	padding: 1px;
	color: var(--custom-dark);
	height: 3rem;
	line-height: 3rem;
	margin: auto;
	font-size: 1.23rem;
	text-align: left;
	width: 100%;
	font-family: Montserrat,Open Sans,sans-serif;
}
.container__buttons {  
	display: grid;
	grid-template-columns: 112px 1fr;
	align-items: center;
	height: 3rem;
}

.center{
	text-align: center;
}

.hide{	
	transition: transform 0.7s cubic-bezier(0.76, 0, 0.24, 1);
	display: none !important;
}

.show{
	transition: transform 0.7s cubic-bezier(0.76, 0, 0.24, 1);
	display: block;
}

.animatehide{
	
	-webkit-transition: -webkit-transform 1s ease;
	-moz-transition: -moz-transform 1s ease;
	-o-transition: -o-transform 1s ease;
	-ms-transition: -ms-transform 1s ease;
	transition: all 1s ease;
	display: none;
}
.animateshow{	
	
	-webkit-transition: -webkit-transform 1s ease;
	-moz-transition: -moz-transform 1s ease;
	-o-transition: -o-transform 1s ease;
	-ms-transition: -ms-transform 1s ease;
	transition: all 1s ease;
	display: block; 
}


.dark .incsizespansms, .dark .incsizespan{
	color: #fff;
	background: rgba(35,82,124,.4);
}

.dark .pricetext{
	color: #fff;
	font-weight: 500;
}

.pricetext{
	color: var(--custom-dark);
	font-weight: 500;
}

.incsizespansms {
    border: 1px solid var(--custom-dark);
    border-radius: 5px;
    position: relative;
    z-index: 0;
    padding: 10px;
    color: var(--custom-dark);
	background: var(--custom-med);
	line-height: 1.4rem;
	letter-spacing: 0.02rem;
	display: flow-root;
	width: 100%;
}


.incsizespan {
    border: 1px solid var(--custom-dark);
    border-radius: 5px;
    position: relative;
    z-index: 0;
    padding: 10px;
    color: var(--custom-dark);
	background: var(--custom-med);
	line-height: 1.4rem;
	letter-spacing: 0.02rem;
	display: none;	
}

.incsizespan_error {
    border: 1px solid var(--error-dark);
    border-radius: 5px;
    position: relative;
    z-index: 0;
    padding: 10px;
    color: var(--error-dark);
	background: var(--error-med);
	line-height: 1.4rem;
	letter-spacing: 0.02rem;
	display: block;
}

.incsizespan_success{
	border: 1px solid var(--success-dark);
    border-radius: 5px;
    position: relative;
    z-index: 0;
    padding: 10px;
    color: var(--success-dark);
	background: var(--success-med);
	line-height: 1.4rem;
	letter-spacing: 0.02rem;
	display: block;
}


.incsizespan_info{
	border: 1px solid var(--info-dark);
    border-radius: 5px;
    position: relative;
    z-index: 0;
    padding: 10px;
    color: var(--info-dark);
	background: var(--info-med);
	line-height: 1.4rem;
	letter-spacing: 0.02rem;
	display: block;
}




.container__buttons button {
	font-weight: 400;
	transition: transform 150ms ease-in-out;
	transform: translate3d(0px, 0px, 0px);  	
	border: 1px solid var(--custom-dark);
	border-radius: 5px;
	position: relative;
	z-index: 0;	
	color: #ffffff;
	height: 3rem;
	line-height: 3rem;
	margin: auto;
	font-size: 1.5rem;
	width: 100%;
	background: var(--custom-dark);
}
.container__buttons button:hover {
	transform: scale(1.075);
}


@media only screen and (max-width: 32rem) {
	.stack {
		--space: 0.7rem;
	}
	.stack > * + * {
		margin-top: var(--space);
	}
	.container__control--switch .switch-btn, .container__control--select .select-input, .container__control--input .text-input, .incsizespansms, .incsizespan, .incsizespan_error, .incsizespan_success, .container__buttons {
		max-width: 100%;
	}
	.dark .maincontainer, .dark .maincontainer2, .maincontainer, .maincontainer2 {
		padding: 0;
		padding-bottom: 2rem;
		box-shadow: none;		
		background: transparent;
		left: 0;
		margin: 0 auto;
		margin-top:220px;
		max-width: 20rem;
	}
	.container__buttons {
		grid-template-columns: 1fr; 
	}
	.container__controls2 {		
		margin-bottom: 1.5rem;
	}
	.po-wrap {
		padding: 0;
	}
	.statustimeline {
		width: 24rem;
	}
	
}


@media only screen and (min-width: 32rem) {
	.dark .maincontainer, .dark .maincontainer2, .maincontainer, .maincontainer2 {
		padding: 3rem;	
		margin-top: 140px;
		left: 0;
		margin-left: 0;
	}
	.container__buttons {
		grid-template-columns: 1fr; 
	}
	.container__controls2 {		
		margin-bottom: 1.5rem;
		transition: background-color .2s ease-in-out;
		border-radius: 12px;
		margin-bottom: 20px;
		margin-top: 20px;
	}
	.po-wrap {
		padding: 0;
	}
}

@media only screen and (min-width: 40rem) {
	.dark .maincontainer, .dark .maincontainer2, .maincontainer, .maincontainer2 {
		padding: 3rem;	
		margin-top:140px;
		left: 50%;
		margin-left: -20rem;
	}
}

@media only screen and (min-width: 62rem) {
  .dark .maincontainer, .maincontainer {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
	align-items: center;
    max-width: 62rem;    
	left: 50%;
	margin-left: -31rem;
  }
  
  .dark .maincontainer2, .maincontainer2 {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 62rem;
    align-items: top;
	left: 50%;
	margin-left: -31rem;
  }
  .container__canvas {
    grid-column: 1;
    margin-bottom: 0;
    max-width: 100%;
  }
  .container__controls {
    grid-column: 2;
    padding: 1rem 1.5rem;
	word-break: break-word;
  }
  
  .container__controls2 {
    grid-column: 1;
    padding: 1rem 1.5rem;
	word-break: break-word;
  }
  
  .wallet__content {
    min-width: 350px;
	grid-column: 2;
	padding: 1rem 0.1rem;
  }
  
  .po-wrap {
    padding: 5px 20px 30px;
  }
  
  .po-crypto__email-step {
    padding: 20px 25px 30px;
	margin-bottom: 20px;
  }
  
  .container__buttons {
    grid-column: 2;
    grid-template-columns: 1fr;
  }

  .container__control--input .text-input {
    border: 1px solid var(--custom-dark);
	border-radius: 5px;
	position: relative;
	z-index: 0;	
	padding: 0 20px;
	color: var(--custom-dark);
	height: 3rem;
	line-height: 3rem;
	margin: auto;
	font-size: 1.23rem;
	text-align: left;
	width: 100%;
	font-weight: 300;
  }
}

button {
  font-family: inherit;
  cursor: pointer;
  font-size: 0.875rem;
}

label {
  display: block;
}

/* .center-xy {
  display: grid;
  place-items: center;
} */


button,
input {
  outline-offset: 0.16rem;
}
button:focus,
input:focus {
  outline: none;
}

.Rainbow{
	background: linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: bold;
	
	/* background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
    -webkit-background-clip: text; */
}

.rateTable {
	width: 100%;
	border-spacing: 0;
	border-collapse: collapse;
}
.rateTable th {
	float: initial;
	padding-top: 10px;
	padding-bottom: 10px;
}
.rateTable td {
	padding-top: 10px;
	padding-bottom: 10px;
}
.rateTable tr {
	border-bottom: 1px solid #23527c;
}
.rateRow {
	align-items: center;
	display: flex;
}

.Context {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
	display: inline-grid;
	padding: 50px;
}

.Context {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 1px;
    -ms-flex-preferred-size: 1px;
    flex-basis: 1px;
}


.Context__header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
}

.Context__copy {
    font-size: 20px;
    font-weight: 300;
}
.Context__copy {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}

.form .fixpost {
    display: inline-block;
    vertical-align: top;
}
.fixpost {
    position: relative;
    overflow: hidden;
}
.password .action_password {
    position: absolute;
    right: 20px;
    top: 18px;
    z-index: 2;
    cursor: pointer;
}



.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.container {
    max-width: 540px;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
	position: absolute;
    left: calc(50% - 270px);
    z-index: 3;
}
#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
}

.alert-primary {
    color: var(--custom-dark);
    background-color: var(--custom-light);
    border-color: var(--custom-dark);
}
.alert-dismissible {
    padding-right: 4rem;
}
.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.alert-heading {
    color: inherit;
	margin-bottom: 1rem !important;
}
.h4, h4 {
    font-size: 1.5rem;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.fade {
    transition: opacity .15s linear;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit;
}

[type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}


.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-primary {
    color: #fff;
    background-color: var(--custom-dark);
    border-color: var(--custom-dark);
}



#sellcurrencydirswitch, #buycurrencydirswitch, #depositcurrencydirswitch, #logindirswitch, #selldisplay, #buydisplay, #depositdisplay{
	position: relative;
	max-height: 0;
	transition: all 0.1s ease;
	overflow: hidden;
	margin-top: 0;
}

#sellcurrencydirswitch.disshow, #buycurrencydirswitch.disshow, #logindirswitch.disshow, #depositcurrencydirswitch.disshow, #selldisplay.disshow, #buydisplay.disshow, #depositdisplay.disshow{
	position: relative;
	max-height: 100%;
	transition: all 0.1s ease;
	margin-top: 1rem;
}

#sellcurrencydirswitch.dishide, #buycurrencydirswitch.dishide, #logindirswitch.dishide, #depositcurrencydirswitch.dishide, #selldisplay.dishide, #buydisplay.dishide, #depositdisplay.dishide{
	display: none !important;
}





.acc__item {	
    /*box-shadow: 22px 22px 68px 0 rgba(0,0,0,.1);*/
    /* border: none; */
    transition: background-color .2s ease-in-out;
    border-radius: 12px;
    margin-bottom: 20px;
    margin-top: 20px;
	border: 1px solid var(--custom-med);
}

.acc__toggler {
    padding-right: 50px;
	z-index: 100;
}
.acc__toggler, .order-box-item {
    position: relative;
    padding: 20px;
    padding-right: 25px;
    min-height: 70px;
    display: flex;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
	cursor: pointer;
}

a, a:hover {
    color: inherit;
    text-decoration: none;
}

.wallet_icons{
	padding-top: 17px;
    max-width: auto !important;
    max-height: 30px !important;
    width: auto !important;
    height: auto !important;
}

img.acc__toggler-icons {
    margin: 0 0 0;
    max-width: auto !important;
    max-height: 40px !important;
    width: auto !important;
    height: auto !important;
    margin-top: 5px;
}
.img-responsive {
    display: block;
    max-width: 100%;
    flex-shrink: 0;
    height: auto;
}

.acc__toggler::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 80px;
    background-size: 24px 24px;
    background-position: 50%;
    background-image: url(./components/images/arrow.svg);
    background-repeat: no-repeat;
    pointer-events: none;
    transition: transform .2s ease-in-out;
}

.acc__wrap {
    position: relative;
}

.dark .acc__item.active {
    border: 2px solid var(--dark-custom-med);
}

.acc__item.active {
    border: 2px solid var(--custom-dark);
	padding:10px;
}

.acc__item:first-of-type {
    margin-top: 0;
}

.acc__item .acc__content{
    height: 0px;
    transition:height 0.05s ease-out;
    -webkit-transform: scaleY(0);
	-o-transform: scaleY(0);
	-ms-transform: scaleY(0);
	transform: scaleY(0);
    display: block;    
}


.active .acc__content{
	height: auto;
    -webkit-transform: scaleY(1);
	-o-transform: scaleY(1);
	-ms-transform: scaleY(1);
	transform: scaleY(1);
    -webkit-transform-origin: top;
	-o-transform-origin: top;
	-ms-transform-origin: top;
	transform-origin: top;

	-webkit-transition: -webkit-transform 0.4s ease-out;
	-o-transition: -o-transform 0.4s ease;
	-ms-transition: -ms-transform 0.4s ease;
	transition: transform 0.4s ease;
	display: block;
}

.active .acc__toggler{
    -webkit-border-top-left-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-topright: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    -webkit-border-bottom-right-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius-bottomright: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.acc__togglerbg{
	background: var(--custom-light);
	border-radius: 10px !important;
}

.dark .acc__togglerbg{
	background: var(--dark-custom-med);
	border-radius: 10px;
}

#ngnbaldisplay{
	margin-bottom: 0 !important;
}

.text-uppercase {
    text-transform: uppercase;	
}
.available{
	border-bottom: 1px dashed #919eab;
	font-size: 14px;
    line-height: 1.2em;
    color: #919eab;
    padding-bottom: 10px;
    padding-top: 8px;
    display: inline-block;
	text-align: center;
}
.mb-15 {
    margin-bottom: 15px;
}
.help-text {
    font-size: 13px;
    line-height: 1.2em;
    color: #919eab;
    padding-bottom: 10px;
    padding-top: 8px;
    display: inline-block;
}

.crypto-list__row {
    display: flex;
    flex-wrap: wrap;
	width: 100%;
	justify-content: center;
	align-items: space-between;
}

.addressdisplay {
    text-align: center;
	word-break: break-all;
	line-height: 1.5rem;
}

.crypto-list__col {
    width: 50%;
    padding: 0 5px 10px;
}

.dark .crypto__btn.active .crypto__ico-wrap {
	border: 3px solid #fbb03b;
  box-shadow: 0 0 0 0px var(--custom-dark);
	background-color: transparent;
}
.dark .crypto__ico-wrap {
	background: var(--dark-custom-dark);
}
.dark .crypto__btn {
	color: #fff;
}

.crypto__btn {
    display: block;
    background-color: initial;
    border: 0;
    width: 100%;
    margin: 0 auto;
    color: #212b36;
    font-size: 12px;
    line-height: 1.2em;
    text-align: center;
    padding: 0;
    cursor: pointer;
}

.crypto__btn.active{
		color: #000;
}

.dark .crypto__btn.active{
		color: #fff;
}

.crypto__btn.active .crypto__ico-wrap {
    border: 2px solid var(--custom-dark);
    box-shadow: 0 0 0 0px var(--custom-dark);
		background-color: var(--custom-light);
}

.crypto__ico-wrap {
    position: relative;
    display: block;
    width: 100%;
    max-width: 66px;
    margin: 0 auto 5px;
    background-color: #fff;
    border: 1px solid var(--custom-med);
    border-radius: 100%;
    transition: border .2s ease-in-out,box-shadow .2s ease-in-out;
}

.crypto__ico {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 32px;
    transform: translate(-50%,-50%);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;    
}

.crypto__ico.btc{
	background-image: url(./components/images/btc.svg)
}
.crypto__ico.usdt{
	background-image: url(./components/images/usdt.svg)
}
.crypto__ico.ltc{
	background-image: url(./components/images/ltc.svg)
}
.crypto__ico.bch{
	background-image: url(./components/images/bch.svg)
}
.crypto__ico.eth{
	background-image: url(./components/images/eth.svg)
}

.crypto__ico-wrap::after {
    content: "";
    display: block;
    padding-top: 100%;
}


/* Noselect */
/* on-off switch */


.switch {
	
	/* position: relative;
	line-height: 1;
	white-space: nowrap;
	vertical-align: middle;
	margin: 0;
	box-sizing: border-box; */
	
	position: relative;
	max-height: 0;
	transition: 0.7s;
	overflow: hidden;
	left: calc(50% - 40px);
}

.inlinecenter{
	/* display: inline-block; */
	
	position: relative;
	transition: 0.7s;
	max-height: 100vh;
	left: calc(50% - 40px);
}

.inlineleft{
	display: inline-block;
	float: left;
}
.inlineright{
	display: inline-block;
	float: right;
}

.switch * {
  box-sizing: border-box;
}

.switch,
.switch * {
  outline: none;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
}

.switch__text {
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
}

.switch__checkbox {
  display: none;
  visibility: hidden;
}

.switch__elements {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border: 1px solid var(--custom-dark);
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  width: 60px;
  height: 25px;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
}
.switch__elements::after {
  display: block;
  content: "";
  clear: both;
}

.switch__checkbox:checked ~ .switch__elements {
  border: 1px solid var(--custom-dark);
  background: var(--custom-dark);
}

.switch__inner {
  width: 120px;
  height: 25px;
  display: block;
  line-height: 1;
  -webkit-transition: transform 0.15s ease-in-out;
  -o-transition: transform 0.15s ease-in-out;
  -moz-transition: transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out;
  transition-property: transform;
  position: relative;
  transform: translateX(-60px);
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
               supported by Chrome and Opera */
  border-radius: 20px;
  overflow: hidden;
}
.switch__inner::after {
  display: block;
  content: "";
  clear: both;
}

.switch__checkbox:checked ~ .switch__elements .switch__inner {
  transform: translateX(0px);
}

.switch__left {
  background: var(--custom-dark);
  color: var(--custom-light);
  display: block;
  float:left;
  width: 50%;
  height: 25px;
  padding: 6.5px 20px 0px 0;
  font-size: 11px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  text-align: center;
}

.switch__right {
  background: var(--custom-light);
  color: var(--custom-dark);
  display: block;
  float:left;
  width: 50%;
  height: 25px;
  padding: 6.5px 0 0px 20px;
  font-size: 11px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  transition-property: transform;
  text-align: center;
}

.switch__middle {
  width: 23px;
  height: 23px;
  margin: 0;
  background: #ffffff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  bottom: 0;
  left: calc(50% - 12.5px);
  transform: translateX(13px);
  -webkit-transition: 0.15s ease-in-out;
  -o-transition: 0.15s ease-in-out;
  -moz-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
  transition-property: transform;
}

.switch__checkbox:checked ~ .switch__elements .switch__inner .switch__middle {
  transform: translateX(-12px);
}


.header-container {
   /*  background-image: linear-gradient(91deg, #FFFFFF 1%, #F5F5F5 100%, #F5F5F5 100%); */
    padding: 0px 24px;
}
.container:before, .container:after {
    content: " ";
    display: table;
}

:after, :before {
    box-sizing: border-box;
}
.col {
    flex: 1;
	position: relative;
    width: 100%;
    padding-right: 15px;
}
.navmenu-brand {
    padding: 10px 15px 10px 0px;
}

.navmenu-brand {
    display: block;
    padding: 5px;
    margin: 5px 0;
    font-size: 18px;
    line-height: 20px;
}
a, a:hover {
    color: inherit;
    text-decoration: none;
}
.img-responsive {
    display: block;
    max-width: 100%;
    flex-shrink: 0;
    height: auto;
}
.nav-header-text {
    text-align: center;
    color: #56B14D;
    font-weight: 500;
    font-size: 18px;
}

@media only screen and (min-width: 62rem) {
	.container {
		max-width: 960px;
	}
}
@media only screen and (min-width: 32rem) {
	.scontainer {
		max-width: 720px;
	}
	.scontainer {
		max-width: 100%;
		padding-left: 15px;
		padding-right: 15px;
		margin: 0 auto;
	}
}
@media only screen and (min-width: 62rem) {
	.scontainer {
		width: 960px;
	}
}
@media only screen and (min-width: 32rem) {
	.scontainer {
		width: 736px;
	}
	.scontainer {
		margin-right: auto;
		margin-left: auto;
		padding-left: 8px;
		padding-right: 8px;
	}
}


@media only screen and (max-width: 32rem) {
	
	.statustimeline {
		max-width: 30rem;
	}
}

@media only screen and (max-width: 30rem) {
	
	.statustimeline {
		max-width: 24rem;
	}
}




#headbar.cb-position-bottom {
    top: 0;
    box-shadow: inset 0 2px 0 0 #fbb03b;
}
#headbar, #headbar * {
    box-sizing: border-box;
}
#headbar {
    position: fixed;
    min-width: 100%;
    height: auto;
    z-index: 5;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    left: 0;
    text-align: center;
    font-weight: 400;
}
#headbar #cb-data, #headbar #cb-steps, #headbar .headbar-container {
    justify-content: space-evenly;
}
#headbar .headbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 62px;
    padding: 15px 30px;
    text-align: center;
    width: 100%;
    z-index: 2;
	background: #fff;
	color: var(--custom-dark);
	border-bottom: 2px solid var(--custom-med);
}
.dark #headbar .headbar-container {
	background: var(--dark-custom-dark);
	color: #fff;
}
#headbar #cb-message {
    font-size: 20px;
}
#headbar .headbar-container > div:first-child {
    margin-right: 15px;
}
#headbar #cb-data, #headbar #cb-steps, #headbar .headbar-container {
    justify-content: space-evenly;
}
#headbar #cb-steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#headbar .cb-step:first-child {
    margin-left: 0;
}
#headbar .cb-step {
    margin: 0 15px;
    display: inline-block;
    text-align: left;
}

#headbar .cb-step-icon {
    width: 30px;
    height: 30px;
    float: left;
    margin-right: 10px;
}
#headbar span {
    display: inline-block;
}
#headbar .cb-step-icon img, #headbar .cb-step-icon svg {
    opacity: .6;
}
#headbar .cb-step-name {
    color: #00a99d;
    text-transform: uppercase;
    font-weight: 600;
}
#headbar .cb-step-desc, #headbar .cb-step-name {
    display: block;
}
#headbar span {
    display: inline-block;
}
#headbar #cb-data, #headbar #cb-steps, #headbar .headbar-container {
    justify-content: space-evenly;
}
#headbar #cb-data {
    display: flex;
    justify-content: space-between;
}
#headbar .headbar-container > div:last-child {
    margin-left: 15px;
}
#headbar #cb-data > span:not(:last-child) {
    margin-right: 10px;
	align-items: center;
	display: flex;
}
#headbar .cb-number, #headbar .cb-number-desc {
    vertical-align: text-bottom;
}
#headbar .cb-number {
    font-size: 26px;
    color: #fbb03b;
}
#headbar span {
    display: inline-block;
}
#headbar .cb-number-desc {
    color: #00a99d;
    margin-left: 10px;
}
#headbar .cb-close-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -10px;
    width: 15px;
    height: 15px;
    opacity: .5;
    padding: 10px;
    outline: none;
}
#headbar .cb-close-icon::after, #headbar .cb-close-icon::before {
    position: absolute;
    content: " ";
    height: 15px;
    width: 2px;
    top: 3px;
    background-color: #fff;
}
#headbar .cb-close-icon::before {
    transform: rotate(45deg);
}

#headbar .cb-close-icon::after, #headbar .cb-close-icon::before {

    position: absolute;
    content: " ";
    height: 15px;
    width: 2px;
    top: 3px;
    background-color: #fff;

}
#headbar .cb-close-icon::after {

    transform: rotate(-45deg);

}
@media (max-width:900px){
	#headbar .headbar-container {
		flex-direction: column;
	}
	#headbar .headbar-container > div {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	#headbar .cb-step-icon {
		display: none;
	}
	#headbar #cb-steps {
		margin: 10px 0;
		margin-right: 0px;
		margin-left: 0px;
	}
	

}

@media (max-width: 62rem){
	.maincontainer2 .Context {
		padding: 50px 0 !important;
	}
}

@media (max-width:480px){
	#headbar{
		font-size: 12px;
	}
	#headbar .headbar-container {
		padding: 15px 25px;
	}
	#headbar .cb-step {
		margin: 0 5px;
		margin-left: 5px;
	}
	#headbar .cb-step:first-child {
		margin-left: 0;
	}
	
}

.marquee {
  width: 80vw;
  height: 20vh;
  overflow: hidden;  
}
.marquee:before, .marquee:after {
  position: absolute;
  top: 0;
  width: 10rem;
  height: 100%;
  content: "";
  z-index: 1;
}

.marquee:before {
  left: 0;
  background: linear-gradient(to right, #E9EDF1 0%, transparent 100%);
}
.marquee:after {
  right: 0;
  background: linear-gradient(to left, #E9EDF1 0%, transparent 100%);
}

.dark .marquee:before {
  left: 0;
  background: linear-gradient(to right, #000 0%, transparent 100%);
}
.dark .marquee:after {
  right: 0;
  background: linear-gradient(to left, #000 0%, transparent 100%);
}



.marquee .marquee__inner {
  list-style: none;
  height: 100%;
  display: flex;
  animation: scroll 30s linear infinite;
}
.marquee .marquee__inner div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20vw;
  flex-shrink: 0;
  font-size: 1rem;
  white-space: nowrap;
}

@keyframes scroll {
  0% {
    transform: translateX(50vw);
  }
  100% {
    transform: translateX(calc(-1.5 * 50vw));
  }
}

@media (max-width: 600px) {
  {
    font-size: 12px;
  }
  .marquee {
    width: 100vw;
    height: 16vh;
  }
  .marquee:before, html .marquee:after {
    width: 5rem;
  }
  .marquee .marquee__inner div {
    width: 50vw;
  }

  @keyframes scroll {
    0% {
      transform: translateX(150vw);
    }
    100% {
      transform: translateX(calc(-3 * 50vw));
    }
  }
	
}

.maincontain{
	margin-bottom: 150px;
	max-width: 500px;
}


#noticebar.cb-position-bottom, #marquee.cb-position-bottom {
    bottom: 0;
    box-shadow: inset 0 2px 0 0 #fbb03b;
}
#noticebar, #noticebar *, #marquee, #marquee * {
    box-sizing: border-box;
}
.dark #noticebar, .dark #marquee {	
	background: var(--dark-custom-dark);
	color: #fff;
}
#noticebar, #marquee {
    position: fixed;
    min-width: 100%;
    height: auto;
	min-height: 62px;
	padding-top: 12.5px;
    z-index: 4;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    left: 0;
    text-align: left;
    font-weight: 400;
	background: #fff;
	color: #3C2601;
}
#noticebar #cb-data, #noticebar #cb-steps, #noticebar .noticebar-container, #marquee #marquee__inner {
    /* justify-content: space-evenly; */
}
#noticebar .noticebar-container, #marquee .noticebar-container {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    min-height: 62px;
    padding: 15px 30px;
    text-align: left;
    width: 100%;
    z-index: 2;
	position: relative;
	/*background: #3C2601;*/	
	/*border-top: 2px solid var(--custom-med);*/
}
#noticebar #cb-message, #marquee #marquee__inner {
    font-size: 20px;
}
#noticebar .noticebar-container > div:first-child, #marquee .noticebar-container > div:first-child {
    margin-right: 15px;
}
#noticebar #cb-data, #noticebar #cb-steps, #noticebar .noticebar-container {
    /* justify-content: space-evenly; */
}
#noticebar #cb-steps, #marquee #marquee__inner {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}
#noticebar .cb-step:first-child, #marquee .cb-step:first-child {
    margin-left: 0;
}
#noticebar .cb-step, #marquee .cb-step {
    margin: 0 15px;
    display: inline-block;
    text-align: left;
}

#noticebar #highlight span {
	align-items: center;
	display: flex;	
}

#noticebar #highlight h3 {
	align-items: center;
	display: flex;	
}

#noticebar .cb-step-icon, #marquee .cb-step-icon {
    width: 30px;
    height: 30px;
    float: left;
    margin-right: 10px;
}
#noticebar span, #marquee span {
    display: inline-block;
}
#noticebar .cb-step-icon img, #noticebar .cb-step-icon svg, #marquee .cb-step-icon img, #marquee .cb-step-icon svg {
    opacity: .6;
}
#noticebar .cb-step-name, #marquee .cb-step-name {
    color: #00a99d;
    text-transform: uppercase;
    font-weight: 600;
}
#noticebar .cb-step-desc, #noticebar .cb-step-name, #marquee .cb-step-desc, #marquee .cb-step-name {
    display: block;
}
#noticebar #cb-data, #noticebar #cb-steps, #noticebar .noticebar-container {
    /* justify-content: space-evenly; */
}
#noticebar #cb-data {
    display: flex;
    /* justify-content: space-between; */
}
#noticebar .noticebar-container > div:last-child, #marquee .noticebar-container > div:last-child {
    margin-left: 15px;
}
#noticebar #cb-data > span:not(:last-child), #marquee #marquee__inner > span:not(:last-child) {
    margin-right: 10px;
	align-items: center;
	display: flex;
}
#noticebar .cb-number, #noticebar .cb-number-desc, #marquee .cb-number, #marquee .cb-number-desc {
    vertical-align: text-bottom;
}
#noticebar .cb-number, #marquee .cb-number {
    font-size: 26px;
    color: #fbb03b;
}
#noticebar span, #marquee span {
    display: inline-block;
}
#noticebar .cb-number-desc, #marquee .cb-number-desc {
    color: #00a99d;
    margin-left: 10px;
}
#noticebar .cb-close-icon, #marquee .cb-close-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -10px;
    width: 15px;
    height: 15px;
    opacity: .5;
    padding: 10px;
    outline: none;
	cursor: pointer;
}

#noticebar .cb-close-icon::after, #noticebar .cb-close-icon::before, #marquee .cb-close-icon::after, #marquee .cb-close-icon::before {
    position: absolute;
    content: " ";
    height: 15px;
    width: 2px;
    top: 3px;
    background-color: #fff;
}

#noticebar .cb-close-icon::before, #marquee .cb-close-icon::before {
    transform: rotate(45deg);
}

#noticebar .cb-close-icon::after, #noticebar .cb-close-icon::before, #marquee .cb-close-icon::after, #marquee .cb-close-icon::before {

    position: absolute;
    content: " ";
    height: 15px;
    width: 2px;
    top: 3px;
    background-color: #fff;

}
#noticebar .cb-close-icon::after, #marquee .cb-close-icon::after {

    transform: rotate(-45deg);

}
@media (max-width:900px){
	#noticebar .noticebar-container, #marquee .noticebar-container {
		flex-direction: column;
	}
	#noticebar .noticebar-container > div, #marquee .noticebar-container > div {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	#noticebar .cb-step-icon, #marquee .cb-step-icon {
		display: none;
	}
	#noticebar #cb-steps, #marquee #marquee__inner {
		margin: 10px 0;
		margin-right: 0px;
		margin-left: 0px;
		justify-content: space-evenly;
	}
}
@media (max-width:480px){
	#noticebar, #marquee{
		font-size: 12px;
	}
	#noticebar .noticebar-container, #marquee .noticebar-container {
		padding: 15px 25px;
	}
	#noticebar .cb-step, #marquee .cb-step {
		margin: 0 5px;
		margin-left: 5px;
	}
	#noticebar .cb-step:first-child, #marquee .cb-step:first-child {
		margin-left: 0;
	}
}








ul {
  list-style-type: none;
  padding: 0.5rem 1rem;
  margin: 0 0 0.5rem;
}

li {
  display: inline-block;
  padding: 0.85rem 1rem;
}

.notification-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-grid;
  display: grid;
  padding: 0.65rem 0;
}
#heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-grid;
  display: grid;
  padding: 0.5rem 1rem;
  border-bottom: 0.01rem solid #eee;
}

.notification-link {
  position: absolute;
  margin: 0.4rem 0;
}

.heading-left, .img-left {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-grid-row: 1;
  grid-row: 1;
  margin: 0 0 0 0.5rem;
}

.heading-left, .user-content {
  grid-column: span 9;
  width: 14rem;
}

.heading-right, .img-left {
  grid-column: auto;
}

.heading-right, .user-content {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -ms-grid-row: 1;
  grid-row: 1;
}

.heading-right {
  width: 2.5rem;
}

.img-left {
  width: 3rem;
}

.user-photo {
  display: inline-block;
  vertical-align: middle;
  height: 3rem;
  width: 3rem;
  margin: 0 0.5rem 0 0;
  border-radius: 50%;
  max-width: 100%;
}

p.user-info {
  margin: 0.5rem 0 0 0;
}
p.time {
  margin: 0.3rem 0 0 0;
  color: #9da4ae;
}

span.name {
  font-weight: 600;
}

.fadeStart-enter-active {
  -webkit-animation: fadeStart 0.2s both ease-in-out;
  animation: fadeStart 0.2s both ease-in-out;
}

.fadeStart-leave-active {
  -webkit-animation: fadeEnd 0.2s both ease-in-out;
  animation: fadeEnd 0.2s both ease-in-out;
}

[v-cloak] > * {
  display: none;
}

@-webkit-keyframes fadeStart {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    display: block;
  }
}
@keyframes fadeStart {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    display: block;
  }
}
@-webkit-keyframes fadeEnd {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
}
@keyframes fadeEnd {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
}


#spendOption ol {
    align-items: flex-start;
    display: inline-flex;
}


.dark .statustimeline {
	background: var(--dark-custom-dark);
}

.statustimeline {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	z-index: 1;
	position: relative;
	/* display: grid;
	grid-template-columns: 1fr 1fr; */
	padding: 34px 30px;
	background: #fff;
	border-radius: 18px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	letter-spacing: normal;
}

.statustimeline__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  border: 2px solid var(--custom-light);
  padding: 35px 35px;
  border-radius: 5px;
  position: relative;
}

.dark .statustimeline__header {
  border: 2px solid var(--dark-custom-med);
}

.statustimeline__header::after {
  content: "";
  width: 100%;
  height: 70px;
  bottom: 0px;
  left: 0px;
  position: absolute;
  z-index: 2;
  background-color: var(--custom-light);
}

.dark .statustimeline__header::after {
  background-color: var(--dark-custom-med);
}

.statustimeline__header span {
	margin-bottom: 10px;
	text-align: center;  
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: bold;
}

.statustimeline__header span.toggleInfo {
	margin-bottom: 30px;
	z-index: 3;
}

.statustimeline__header h1 {
	margin-bottom: 40px;
	font-size: 1.8rem;
	color: #262245;
	text-align: center;
}
.statustimeline__header p {
	margin-bottom: 100px;
	font-size: 1rem;
	/* color: var(--custom-dark); */
	text-align: center;
	z-index: 3;
	position: relative;
	text-transform: none;
	word-wrap: break-word;
	max-width: 350px;
}

.dark .statustimeline__header h1 {
	color: var(--dark-custom-light);
}

.statustimeline__header p span {
	/* color: var(--custom-dark); */
	text-transform: none;
	word-wrap: break-word;
	width: 100%;
	display: block;
	line-height:1.5rem;
}

.statustimeline__header .statusprocess {
  height: 8px;
  background-color: #fff;
  border-radius: 3px;
  z-index: 4;  
}

@keyframes loading {
    0% {
        background-position-x: -800px;
    }
}

.statustimeline__header .statusprocess .xd1 {
  width: 33.3%;
  border-radius: 3px;
  height: 8px;
  background-color: var(--custom-dark);
  position: relative;
  background: linear-gradient(to right, #e9edf1 0%, #a7b9ca 20%, #6585a3 40%, #23527c 100%);
  background-size: 800px 104px;
  animation: loading 1s linear infinite forwards
}

.statustimeline__header .statusprocess .xd2 {
  width: 66.7%;
  border-radius: 3px;
  height: 8px;
  background-color: var(--custom-dark);
  position: relative;
  background: linear-gradient(to right, #e9edf1 0%, #a7b9ca 20%, #6585a3 40%, #23527c 100%);
  background-size: 800px 104px;
  animation: loading 1s linear infinite forwards
}

.statustimeline__header .statusprocess .xd3 {
  width: 100%;
  border-radius: 3px;
  height: 8px;
  background-color: var(--custom-dark);
  position: relative;
}

.statusline {
  padding: 10px 90px;
  position: relative;
  margin-top: 50px;
}


.statusline.completed:after
{
    content:"SUCCESS";
    position:absolute;
    top:80px;
    left:0;
    -webkit-transform: rotate(-45deg); /* Safari */
    -moz-transform: rotate(-45deg); /* Firefox */
    -ms-transform: rotate(-45deg); /* IE */
    -o-transform: rotate(-45deg); /* Opera */
    transform: rotate(-45deg);
    font-size:60px;
    color:green;
    border:solid 4px green;
    padding:5px;
    border-radius:5px;
    zoom:1;
    filter:alpha(opacity=20);
    opacity:0.2;
    -webkit-text-shadow: 0 0 2px green;
    text-shadow: 0 0 2px green;
    box-shadow: 0 0 2px green;
}

.statusline.pending:after
{
    content:"PENDING";
    position:absolute;
    top:70px;
    left:0;
    -webkit-transform: rotate(-45deg); /* Safari */
    -moz-transform: rotate(-45deg); /* Firefox */
    -ms-transform: rotate(-45deg); /* IE */
    -o-transform: rotate(-45deg); /* Opera */
    transform: rotate(-45deg);
    font-size:60px;
    color:orange;
    border:solid 4px orange;
    padding:5px;
    border-radius:5px;
    zoom:1;
    filter:alpha(opacity=20);
    opacity:0.2;
    -webkit-text-shadow: 0 0 2px orange;
    text-shadow: 0 0 2px orange;
    box-shadow: 0 0 2px orange;
}

.statusline.cancelled:after
{
    content:"CANCELLED";
    position:absolute;
    top:70px;
    left:0;
    -webkit-transform: rotate(-45deg); /* Safari */
    -moz-transform: rotate(-45deg); /* Firefox */
    -ms-transform: rotate(-45deg); /* IE */
    -o-transform: rotate(-45deg); /* Opera */
    transform: rotate(-45deg);
    font-size:50px;
    color:red;
    border:solid 4px red;
    padding:5px;
    border-radius:5px;
    zoom:1;
    filter:alpha(opacity=20);
    opacity:0.2;
    -webkit-text-shadow: 0 0 2px red;
    text-shadow: 0 0 2px red;
    box-shadow: 0 0 2px red;
}


.statusline::before {
  position: absolute;
  width: 2px;
  height: 100%;
  left: 90px;
  background-color: var(--custom-light);
  content: "";
}

.statusline .statuscontent {
  position: relative;
  padding-left: 30px;
  z-index: 2;
}

.statusline .statuscontent + .statuscontent {
  margin-top: 40px;
}

.statusline .statuscontent h1 {
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 15px;
}

.statusline .statuscontent p {
  font-size: 14px;
}

.statusline .statuscontent::before {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: var(--custom-dark);
  content: "";
  left: -9px;
  border-radius: 50%;
}

.statusline .statuscontent.pending::before {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: var(--custom-light);
  content: "";
  left: -9px;
  border-radius: 50%;
}

.statusline .statuscontent.level::before {
	background: var(--custom-dark);
	border: 1px solid var(--custom-dark);
}

.statusline .statuscontent.pulselevel::before {
	background: var(--custom-dark);
	border: 1px solid var(--custom-dark);
	-webkit-animation: pulser 1s ease infinite;
	animation: pulser 1s ease infinite;
}


@-webkit-keyframes pulser {
  0% {
    -webkit-box-shadow: 0px 0px 0px 0px var(--custom-dark);
    box-shadow: 0px 0px 0px 0px var(--custom-dark);
  }
  100% {
    -webkit-box-shadow: 0px 0px 0px 10px transparent;
    box-shadow: 0px 0px 0px 10px transparent;
  }
}

@keyframes pulser {
  0% {
    -webkit-box-shadow: 0px 0px 0px 0px var(--custom-dark);
    box-shadow: 0px 0px 0px 0px var(--custom-dark);
  }
  100% {
    -webkit-box-shadow: 0px 0px 0px 10px transparent;
    box-shadow: 0px 0px 0px 10px transparent;
  }
}


.sendclick{
	color: #4c4cff;
	cursor: pointer;
	display: block;
}

#toggleInfo {
    text-decoration: underline;
}

.sendsellrow{
	color: var(--custom-dark) !important;
	border-bottom: 2px dotted var(--custom-med);
	cursor: pointer;
}

.dark .sendsellrow{
	color: var(--dark-custom-light) !important;
}

.winwrap{
	top: 5%;
	position: relative;
	letter-spacing: normal;
}

/* .winbox{
	position: absolute;
	left: 50%;
	margin-left: -200px;
	top: 30px;
	bottom: 30px;
}
.winbox.modal::after {
	bottom: -200vh;
} */

.wininput{
	left: 7.5%;
	position: relative;
	padding: 10px;
	border: 1px solid var(--custom-dark);
	border-radius: 5px;
	line-height: 20px;
	font-size: 1rem;
	width: 85%;
	margin-bottom: 10px;
	text-align: center;
}

.winselect{
	left: 7.5%;
	position: relative;
	padding: 10px;
	border: 1px solid var(--custom-dark);
	border-radius: 5px;
	line-height: 20px;
	font-size: 1rem;
	width: 85%;
	margin-bottom: 10px;
	text-align: center;
}

.winbutton{
	left: 7.5%;
	position: relative;
	padding: 10px;
	background: var(--custom-dark);
	border-radius: 5px;
	line-height: 20px;
	font-size: 1rem;
	width: 85%;
	color: #fff;
	transition: transform 150ms ease-in-out;
	transform: translate3d(0px, 0px, 0px);  	
	border: 1px solid var(--custom-dark);
	margin-bottom: 7.5%;
}

.winresponseerror {
    border: 1px solid var(--error-dark);
    border-radius: 5px;
    position: relative;
    padding: 10px;
	left: 7.5%;
    color: var(--error-dark);
	background: var(--error-med);
	line-height: 20px;
	display: block;
	width: 85%;
	font-size: 0.85rem;
	word-wrap: break-word;
	margin-bottom: 10px;
}

.winresponseinfo {
    border: 1px solid var(--info-dark);
    border-radius: 5px;
    position: relative;
    padding: 10px;
	left: 7.5%;
    color: var(--info-dark);
	background: var(--info-med);
	line-height: 20px;
	display: block;
	width: 85%;
	font-size: 0.85rem;
	word-wrap: break-word;
	margin-bottom: 10px;
}





img {
  max-width: 100%;
}

.card {
  overflow: hidden;
}

.card-header {
  height: 200px;
}

.card-header img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.card-content {
  padding: 30px;
}

.card-title {
  height: 20px;
  margin: 0;
}

.card-excerpt {
  color: #777;
  margin: 10px 0 20px;
}

.author {
  display: flex;
}

.profile-img {
  border-radius: 50%;
  overflow: hidden;
  height: 40px;
  width: 40px;
}

.author-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 10px;
  width: 100px;
}

.author-info small {
  color: #aaa;
  margin-top: 5px;
}


.dark .wb-body {
	color: var(--custom-dark);
	background: #fff;
}

.dark .po-crypto__email-step, .dark .cb-number, .dark .siteorder, .dark .Context__header, .dark .img-left, .dark .pimg {
	--fill: #fff;
}
.dark .cb-number svg path, .dark .siteorder svg path, .dark .po-crypto__email-step svg path, .dark .img-left svg path, .dark .pimg svg path , .dark .Context__header svg path, .dark .Context__header svg text {
    /* transform: translate(0%, 35%) scale(0.8); */
	fill: var(--fill);
}

.dark .cb-number svg, .cb-number svg {
    transform: translate(0%, 35%) scale(1.3);
}

.siteorder svg {
    transform: translate(0%, 35%) scale(0.8);
}

.po-crypto__email-step svg {
    transform: translate(-15%,35%) scale(0.5);
}

.pimg svg {
    transform: translate(-15%,0%) scale(0.8);
}

.dark .animated-bg {
  background-image: linear-gradient(
    to right,
    #4e4e4f 0%,
    #3a3a3b 10%,
    #272727 20%,
    #090909 100%
  );
  background-size: 200% 100%;
  animation: bgPos 1s linear infinite;
}

.animated-bg {
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 10%,
    #f6f7f8 20%,
    #f6f7f8 100%
  );
  background-size: 200% 100%;
  animation: bgPos 1s linear infinite;
}

.animated-bg-text {
  border-radius: 50px;
  display: inline-block;
  margin: 0;
  height: 10px;
  width: 100%;
}

@keyframes bgPos {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -150% 0;
  }
}

.sitefooter{
	color: #666;
	position: relative;
	margin-top: 50px;
	text-align: center;
	line-height:40px;
	margin-bottom: 60px;
}

.maincontainer{
	margin-bottom: 100px;
}







.messageapp {
  overflowY: hidden;
  max-width: 375px;  
  margin: 3rem auto;
  border-radius: 24px;
  max-height: 500px;
}

.messages {
  padding: 0 0 2rem;
}

.messagebutton {
  cursor: pointer;
  border: none;
  color: var(--custom-dark);
  background: none;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  transition: all 300ms ease;
}

.messagebutton:hover,
.messagebutton--active {
  opacity: 0.8;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
}

.messagebutton--active {
  opacity: 1;
  font-weight: 700;
}

.messagebutton--contained {
  padding: 0.6rem 1.3rem;
  background-color: var(--custom-light);
  color: #ffffff;
}

.messagebutton--center {
  display: block;
  margin: 0 auto;
}


.messages__filter {
  margin-bottom: 1rem;
}

.messages__list {
  /* margin-bottom: 1.8rem; */
  overflow-y: auto;
	max-height: 500px;
}

.messages__item {
  display: flex;
  padding: 1rem 1rem;
  transition: all 300ms ease;
}

.messages__item:hover {
  /* transform: scale(1.2);
  box-shadow: 0 0 10px rgba(0,0,0,0.4); */
}

.messages__item:not(:last-child) {
  border-bottom: 1px solid var(--custom-dark);
}

.messages__pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}

.messages__content {
  width: 100%;
}

.messages__heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.1rem;
}

.messages__name {
  font-weight: 500;
}

.messages__time {
  font-size: 0.9rem;
  font-weight: 400;
}

.dark .messages__time2 {	
	cursor: pointer; 
	color: #fff; 
	border-bottom: 1px dashed #fff;
}

.messages__time2 {	
	cursor: pointer; 
	color: blue; 
	border-bottom: 1px dashed blue;
}

.dark .messages__time3 {	
	cursor: pointer; 
	color: #fff; 
	text-decoration: underline;
}

.messages__time3 {	
	cursor: pointer; 
	color: blue; 
	text-decoration: underline;
}




.messages__text {
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.2;
}

.messages__text--read,
.messages__time--read {
  opacity: 0.6;
  font-weight: 400;
}

pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
	    font-size: 1rem;
}

pre b {
    color: #fff;
}


.winbox{
	width: 350px !important;
	height: 400px !important;
	left: calc(50%-175px) !important;
	top: calc(50%-200px) !important;
}
